.outerBox {
    display: inline-block;
    transform: translateX(+12%);
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    top: 10px;
    position: sticky;
    margin: 10px;
    background-color: rgba(197, 226, 235, 0.7); 
    color: red;
    width: 80%;
    text-align: center;
    z-index: 999;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
}



.banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.7em;
    border: none;
}

.textContainer {
    margin: 3px 1px;
}

.icon {
    height: 30px;
}