.main_container{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 104px;
    background: #DDEBEF;
    border: 0;
}

.reduced_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
}

.container{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 10px;
    justify-content: center;
}

.progressBarContainer{
    width: 100%;
    height: 8px;
    display: flex;
    flex-direction: row;
    background-color: #4EC1E3;
    position: relative;
}

.reducedProgressBarContainer{
    background-color: #4EC1E3;
    width: 200px;
    height: 8px;
    display: flex;
    position: relative;
    flex-direction: row;
}

.progressBarFillerUsage{
    height: 8px;
    background-color: #148AAD;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.progressBarFillerNonUsage{
    height: 8px;
    background: #4EC1E3;
}
@media (max-width:900px){
    .reducedProgressBarContainer{
        width: 100px;
        height: 8px;
        display: flex;
        flex-direction: row;
    }
    .container{
        align-items: flex-end;
    }
    .main_container{    
        align-self: flex-end;
        margin-bottom: 20px;
    }
}