.titleLabel,
.titleLabelBlue {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 39px;
	color: #323539;
}

.titleLabelBlue {
	color: #20acd9;
}

.main_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 3.5vh;
	margin: 0 2%;
	margin-bottom: 2%;
}

.horizontalContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 2%;
}

.verticalContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2vh;
}

.box_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #ffffff;
}

.box_subContainer {
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	gap: 2.5vh;
	margin: 1rem 0;
}

.headerButton {
	display: flex;
	padding: 12px 42px;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border-radius: 10px;
	background: #09556f;
	color: #ffffff;
	border: 0;
}

.imgMain_Container {
	width: 33%;
	display: grid;
	grid-template-columns: 2fr 1fr;	
	border-radius: 26px;
	background: #fff;
}

.imgContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notificationMain_Container {
	width: 100%;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	border-radius: 26px;
	background: #fff;
	padding: 20px 0;
	gap: 3vh;
}

.notificationContainer {
	width: 90%;
	height: 200px;
	display: flex;
	align-items: center;
	flex-direction: column;
	border-radius: 26px;
	background: #fff;
	overflow-y: auto;
}
.notificationContainer::-webkit-scrollbar {
	display: none;
}

.notificationBtnContainer {
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.machineImg {
	width: 200px;
}

.imgButtonContainer {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin: 1rem 2rem 0 0;
}

.imgButton {
	display: flex;
	width: 90%;
	max-width: 100%;
	padding: 5px 8px;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	background: #20acd9;
	color: white;
	border: 0;
	border-radius: 7px;
}

.editButton,
.plusButton {
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 0;
	background: none;
}

.plusButton {
	justify-content: center;
	align-items: center;
	background: #09556f;
	border-radius: 10px;
}

.map_container {
	display: flex;
	width: 33%;
	justify-content: center;
	align-items: center;
	background-color: white;
	flex-direction: column;
}

.map {
	width: 98%;
	height: 250px;
	margin: 1%;
}

.inputContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 2vw;
}

.inputContainer > * {
	display: flex;
	align-items: center;
	justify-content: center;
}

.inputBenchContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 2vw;
}

.inputBenchContainer > * {
	display: flex;
	align-items: center;
	justify-content: center;
}

.benchLabel {
	font-family: Helvetica;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	opacity: 0.7;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 10px;
	width: 100%;
}

.label {
	font-family: Helvetica;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	opacity: 0.7;
	width: 150px;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 10px;
}

.saveButtonContainer {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
}

.saveBtn,
.downloadBtn {
	display: flex;

	padding: 9px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	background: #09556f;
	border: 0;
	color: white;
	font-size: 16px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.downloadBtn {
	display: flex;
	width: 156px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	background: #09556f;
	border: 0;
	color: white;
	font-size: 16px;
	font-family: Helvetica;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.searchBtnContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 1vw;
}

.searchBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;

	width: 144.33px;
	height: 41.33px;
	border: 0;

	background: #09556f;
	border-radius: 10px;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;

	color: #ffffff;
}

.tableHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
}

.downloadBtn_container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
}

.tableContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 4vh;
	background: #ffffff;
	border-radius: 25px;
	margin-bottom: 1rem;
}

.hr {
	width: 90%;
	height: 1px;
	background: #f2f6f6;
	margin: 0.5rem 0;
}

.notificationContainerMobile {
	width: 100%;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
}
@media (max-width: 900px) {
	.saveButtonContainer {
		margin-bottom: 0px;
	}
	.downloadBtn_container {
		display: none;
	}
	.main_container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 3.5vh;
		margin: 0px 20px 0px 20px;
		margin-bottom: 20px;
	}
	.title_container {
		display: flex;
		flex: 1;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 10px;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 700;
		font-size: 23px;
		color: black;
	}
	.subHeader {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		opacity: 0.5;
		color: #323539;
	}
	.tableContainer {
		gap: 0px;
		margin-bottom: 0px;
	}
	.notificationTitleContainer {
		display: flex;
		flex: 1;
		flex-direction: row;
		gap: 8px;
		margin: 8px 0px;
	}
	.notificationContainerMobile {
		width: 100%;
		align-items: flex-start;
	}
	.notificationIcon {
		width: 15px;
		height: 15px;
	}
	.notificationLabel {
		font-size: 15px;
		font-weight: 700;
	}
	.notificationMain_Container {
		padding: 10px 0px;
		border-radius: 15px;
	}
	.notificationContainer {
		width: 100%;
		border-radius: 15px;
	}

	.hr {
		width: 90%;
		border: 1px solid #f2f6f6;
		margin: 0.5rem 0;
	}

	.box_subContainer {
		flex: 1;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: normal;
		justify-content: center;
		background: #ffffff;
		gap: 0px;
		margin: 20px 20px;
	}

	.inputContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 20px;
	}

	.inputBenchContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 20px;
	}

	.map_container {
		display: flex;
		flex: 1;
		width: 100%;
		justify-content: center;
		align-items: center;
		background-color: white;
		flex-direction: column;

		border-radius: 20px;
	}

	.map {
		width: 98%;
		height: 250px;
		margin: 1%;
		border-radius: 20px;
	}
	.onlineContainer {
		display: flex;
		flex: 1;
		flex-direction: row;
		width: 100%;
		align-items: center;
		gap: 8px;
		margin-left: 5px;
	}
	.onlineRoundCss {
		border: 1px solid #54af65;
		border-radius: 360px;
		background-color: #54af65;
		width: 6px;
		height: 6px;
		animation-name: pulse;
		animation-duration: 3s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
	.onlineLabel {
		color: #54af65;
		font-size: 12px;
		font-weight: 400;
		font-family: 'Helvetica';
	}
	@keyframes pulse {
		0% {
			background-color: #54af65;
		}
		50% {
			background-color: white;
		}
		100% {
			background-color: #54af65;
		}
	}
}
