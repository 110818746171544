.container {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5vh;
}

.alertNotificationIconContainer {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 29px;
    background: rgba(0, 160, 208, 0.10);
}

.positiveNotificationIconContainer {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 29px;
    background: rgba(139, 201, 61, 0.10);

}

.negativeNotificationIconContainer {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 29px;
    background: rgba(252, 141, 65, 0.10)
}

.dateLabel {
    min-width: 33%;
    max-width: 33%;
    color: var(--txt-fra, #323539);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
}

.typeLabel {
    max-width: 33%;
    color: var(--txt-fra, #323539);
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

@media (max-width:900px){
    .container {
        gap: 5%;
    }
    .dateLabel{
        min-width: 0px;
        width: 25%;
        display: flex;
        justify-content: center;
    }
    .typeLabel{
        width: 70%;
        max-width: none;
        display: flex;
        align-items: center;
    }
}