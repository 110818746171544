.title {
    font-size: calc(30px + 1vmin);
}

.title_name {
    font-size: calc(4px + 1vmin);
}

.btn_container {
    display: flex;
    /* justify-content: flex-end; */
    gap: 10px;
    align-items: center;
}

.bell_icon {
    min-width: 2.5vmin;
    width: 2vmin;
    max-width: 20%;
    margin-top: 20%;
}
.bell_container_active,
.bell_container {
    background-color: white;
    border: none;
    border-radius: 3vmin;
    width: 6vmin;
    height: 6vmin;
    max-height: 50px;
    max-width: 50px;
    display: flex;
    /* margin: 100%; */
    align-items: center;
    justify-content: center;
}


.profile_container {
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: space-around;
    color: black;
    background-color: #fff;
    width: 100%;
    padding: 0px 5px;
    height: 6.5vh;
    border-radius: 5px;
    gap: 1vh;
}

.profile_name {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* font-size: 80% */
}

.profile_icon {
    color: #fff;
    font-weight: bold;
    background-color: #20ACD9;
    /* font-size: 80%; */
    max-height: 40px;
    max-width: 40px;
    height: 4.7vh;
    width: 2.5vw;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subtitle_name {
    color: #323539;
    opacity: 50%;
    font-size: calc(2px + 1vmin);
}

.header_container {
    display: flex;
    margin: 1.5%;
    width: 96.5%;
    justify-content: space-between;
    align-items: center;
    /* height: 4vmin; */

}

.profile_letter {
    font-size: calc(6px + 1vmin);
}

.logout_container{
    cursor: pointer;
}
.logout_icon{

}
.header_mobile{
    display: none;
}
.close_collapse{
    display: none;
}
.open_collapse{
    display: none;
}





@media (max-width: 900px){
    .header_container{
        display: none;
    }
    .header_mobile{
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        height: 75px;
        margin: 10px 20px 10px 20px;
    }
    .container_logo{
        display: flex;
        flex:1;
    }
    .container_logo:selection{
        background-color: transparent;
        color: transparent;
    }
    .container_action{
        display: flex;
        flex:1;
        flex-direction: row;
        align-items: flex-start;
        gap:8px;
        justify-content: flex-end;
    }
    .bell_container_active,
    .bell_container {
        background-color: white;
        border: none;
        border-radius: 360px;
        width: 40px;
        height: 40px;
        max-height: auto;
        max-width: none;
        display: flex;
        /* margin: 100%; */
        align-items: center;
        justify-content: center;
    }
    .bell_container_active{
        background-color: #fc8d41;
    }
    .bell_container:active{
        opacity: 0.4;
    }
    .user_container:active{
        opacity: 0.4;
    }
    .menu_container:active{
        opacity: 0.4;
    }
    .bell_icon {
        min-width: auto;
        width: 20px;
        height: 20px;
        max-width: none;
        display: flex;
        margin-top: 0px;
        align-items: center;
    }
    .close_collapse{
        z-index: 100;
        display: flex;
        flex:1;
        position: absolute;
        top:40px;
        right: 40px;
        width: 0%;
        height: 0%;
        border-radius: 20px;
        background-color:#20acd9;
        transition: all 0.3s;
        transition-timing-function: linear;
        overflow:hidden ;
    }
    .open_collapse{
        z-index: 100;
        overflow:hidden ;
        position: absolute;
        display: flex;
        top:0px;
        right: 0px;
        width: 100%;
        height: 100vh;
        border-radius: 0px;
        background-color: #20acd9;
        transition: all 0.3s ;
        transition-timing-function: linear;
    }
}

