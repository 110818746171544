.collapse {
	margin-top: 0.5vh;
	margin-bottom: 1vh;
}

.collapseClose {
	background-color: white;
	width: 100%;
	border-radius: 8px;
	border-bottom: none;
	display: flex;
	transition: all 1s linear;
	justify-content: space-between;
}

.collapseClose:active {
	opacity: 0.7;
}

.collapseOpen {
	background-color: white;
	width: 100%;
	border-radius: 0px;
	border-bottom: 1px solid #9b9b9b;
	display: flex;
	justify-content: space-between;
	transition: all 0.5s linear;
}

.titleLabelClose {
	width: 100%;
	padding: 15px 35px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 39px;
    color: #323539;
	transition: all 0.5s linear;
}

.titleLabelOpen {
	width: 100%;
	padding: 15px 35px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 39px;
    color: #323539;
	transition: all 0.5s linear;
}

.titleLabelRight {
	text-align: right;
}

.collapseAnimation_close {
	background-color: white;
	margin-top: -3.5vh;
	width: 100%;
	transition: height 0.5s linear;
	overflow: hidden;
}
.collapseAnimation_open {
	background-color: white;
	margin-top: -3.5vh;
	width: 100%;
	transition: height 0.5s linear;
	overflow: hidden;
	animation: cambiaStile 0.5s ease 0.5s forwards;
}
@keyframes cambiaStile {
	to {
		overflow: visible;
	}
}

@media (max-width: 900px) {
	.collapse {
		margin-top: 0.5vh;
		margin-bottom: 1vh;
	}

	.collapseClose {
		background-color: white;
		width: 100%;
		border-radius: 8px;
		border-bottom: none;
		display: flex;
		transition: all 1s linear;
		justify-content: space-between;
	}

	.collapseClose:active {
		opacity: 0.7;
	}

	.collapseOpen {
		background-color: white;
		width: 100%;
		border-radius: 0px;
		border-bottom: 1px solid #9b9b9b;
		display: flex;
		justify-content: space-between;
		transition: all 0.5s linear;
	}

	.titleLabelClose {
		width: auto;
		padding: 15px;
		font-size: 16px;
		font-weight: 400;
		line-height: 15px;
		color: #20acd9;
		transition: all 0.5s linear;
	}

	.titleLabelOpen {
		width: auto;
		padding: 15px;
		font-size: 16px;
		font-weight: 700;
		line-height: 15px;
		color: black;
		transition: all 0.5s linear;
	}

	.collapseAnimation_close {
		background-color: white;
		margin-top: -3.5vh;
		width: 100%;
		transition: height 0.5s linear;
		overflow: hidden;
	}
	.collapseAnimation_open {
		background-color: white;
		margin-top: -3.5vh;
		width: 100%;
		transition: height 0.5s linear;
		overflow: hidden;
		animation: cambiaStile 0.5s ease 0.5s forwards;
	}
	@keyframes cambiaStile {
		to {
			overflow: visible;
		}
	}
}
