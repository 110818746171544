.loginPage {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    flex: 1;
}

.form_container {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
}

.image_container {
    /* height: 100vh; */
    /* display: flex; */
    /* flex: 0.3; */
}

@media (max-width: 900px) {
    .loginPage {
        display: flex;  
        justify-content: unset;
        align-items: unset;
        height: 100vh;
        width: auto;
        flex: 1;
    }
    
    .form_container {
        display: flex;
        width: auto;
        align-items: unset;
        justify-content: unset;
        flex:1
    }
    
    .image_container {
        display: none;
    }
}