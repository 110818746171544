.containerStyles{
    height: 2px;
    width: 100%;
    margin:2px 0px 4px;
    background-color: #EAF4F7;
  }

  .fillerStyles{
    height: 2px;
    /* width: `${completed}%`; */
    /* background-color: bgcolor; */
    text-align: right;

  }

.labelStyles{
    padding: 5px;
    color: white;
    font-weight: bold;
  }