.menu_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin: 10px 20px;
}
.intestazione{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 75px;
}
.exitIcon_container{
    display: flex;
    align-items: center;
    justify-self: center;
    width: 40px;
    height: 40px;
}
.routeLink_container{
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    gap:10px;
}
.routeLink{
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    gap: 20px;
}
a{
    text-decoration: none;
}
.lable_container{
    display: flex;
    align-items: center;
    
}
.delimiter{
    display: flex;
    border: 0.5px solid white;
    opacity: 0.15;
    margin:0px 10px 0px -10px;
}
.label{
    color: white;
    font-size: 16px;
}
.icon_container_logout{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    width: 35px;
    height: 35px;
    border-radius: 360px;
}
.link_icon_logout{
    display: flex;
}
.link_footer{
    display: flex;
    margin: 10px 20px;
    border: 1px solid white ;
    border-radius:20px ;
    padding: 5px;
    align-items: center;
    justify-content:center;
}
.link_footer_text{
    margin: 0px;
    font-size: 10px;
    color: white;
    text-decoration: underline;
    text-align: center;
}
