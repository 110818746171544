.login_form {
    display: flex;
    /* flex: 0.6; */
    flex-direction: column;
    /* border: 1px solid black; */
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10%;
    /* margin: 50px; */
    height: 70vh;
    /* width: 0vw; */
}

.logo {
    height: 5em;
    width: 8em;
}

.couple {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.input_label {
    margin-bottom: 2px;
}

.login_title {
    font-weight: bolder;
}

.credentials_div {
    display: flex;
    flex-direction: column;
    font-size: calc(1px + 2vmin);
    gap: 10px;
}

.box {
    background-color: white;
    width: 25vw;
    height: 35em;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-around;
    padding: 3em;
}

.login_btn {
    padding: 10px;
    border-radius: 20px;
    border: none;
    background-color: #148AAD;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.box_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.label_container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.forgotPass_label {
    /* text-decoration: none; */
    color: #323539;
    opacity: 0.5;
    text-decoration: none;
    font-size: 12px;
}

.navLink {
    text-decoration: none;
}

@media (max-width: 900px) {
    .login_form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        padding: 0px;
        height: auto;
        width: auto;
        flex: 1
    }

    .login_title {
        margin-bottom: 50px;
    }

    .credentials_div {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: calc(1px + 2vmin);
        gap: 50px;
    }

    .box_btn {
        margin-top: 60px;
    }

    .box {
        background-color: white;
        width: 70%;
        height: auto;
        border-radius: 0px;
        box-shadow: none;
        align-items: center;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
        padding: 0px;
        flex: 1
    }

    .input_label {
        font-size: 12px;
    }

    .forgotPass_label {
        font-size: 10px;
    }

    .form_container {
        display: flex;
        flex: 1
    }
}
