.titleLabel,
.titleLabelBlue {
  width: 100%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 39px;
  color: #323539;
}

.titleLabelBlue {
  color: #20acd9;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4vh;
  margin: 0 2%;
}

.filter_mobile {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2vh;
}

.filterbar_main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  border-radius: 25px;
  background: #ffffff;
}

.filterBar_container {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1vh;
  background: #ffffff;
}

.filterBar_header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.filterLabel {
  width: 44px;
  height: 18px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;

  /* txt FRA */

  color: #323539;

  opacity: 0.8;
}

.filterBar_body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 2vh;
}

.select_container {
  width: 100%;
}

.resetFilters_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  gap: 8px;

  background: #f2f7f7;
  border-radius: 20px;
  border: 0;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  /* txt FRA */

  color: #323539;
}

.resetFiltersIcon {
  width: 10px;
  height: 10px;
}

.box_grafico {
  background-color: white;

  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  min-height: 100%;
  min-width: 400px;
  flex: 0.3;
  padding: 1em 1em;
}

.title_chart,
.subtitle_chart {
  width: 100%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 125.99%;
  /* or 25px */

  /* black FRA */

  color: #1a1b1e;

  opacity: 0.7;
}

.subtitle_chart {
  font-weight: 400;
  font-size: 14px;
}

.title_chart_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.subtitle_chart_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2vh;
}

.charts_container {
  display: flex;
  width: 80%;
  flex-direction: row;
  gap: 3vh;
}

.box_chart {
  display: flex;
  flex-direction: column;
}

.tableHeader {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.table_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  background: #ffffff;
  border-radius: 25px;
  margin-bottom: 1rem;
}

.table_subContainer {
  width: 95%;
  margin-bottom: 1rem;
}

.downloadBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 144.33px;
  height: 41.33px;

  color: white;
  font-weight: bold;
  border: 0;
  /* violet */

  background: #09556f;
  border-radius: 10px;
}

.searchBtnContainer {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.searchBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 144.33px;
  height: 41.33px;
  border: 0;

  background: #09556f;
  border-radius: 10px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  color: #ffffff;
}

.selectOverview {
  border: none;
  background-color: #f2f6f6;
  border-radius: 0.5em;
  padding: 1vh;
  width: 30%;
  font-size: calc(4px + 1vmin);
}
@media (max-width: 900px) {
  .container {
    margin: 0px 20px;
    overflow: hidden;
  }
  .title_container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    color: #323539;
  }
  .title_labelColored {
    color: #20acd9;
  }
  .filter_mobile {
    display: flex;
    align-items: flex-start;
    margin-bottom: unset;
  }
  .filterBar_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1vh;
    background: transparent;
  }
  .filterBar_body_hide {
    overflow: hidden;
    background-color: white;
    gap: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 0px;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    transition: all 0.3s linear;
  }
  .filterBar_body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    padding: 10px 0px;
    background-color: white;
    z-index: 1;
    height: 500px;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    transition: all 0.3s linear;
    animation: cambiaStile 0.5s ease 0.5s forwards;
  }
  @keyframes cambiaStile {
    to {
      overflow: visible;
    }
  }
  .select_container {
    display: flex;
    flex: 1;
    width: 100%;
  }
  .resetFilters_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0px 10px 20px 10px;
    padding: 15px 0px;
    gap: 8px;
    background: #f2f7f7;
    border-radius: 5px;
    border: 1px solid #20acd9;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #323539;
  }
  .charts_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0px;
    gap: 20px;
  }
  .box_grafico {
    background-color: white;
    border: none;
    border-radius: 10px;
    /* height: 10vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: unset;
    min-width: unset;
    flex: 0.3;
    padding: 0px 0px;
  }
  .box_chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .title_chart_container {
    margin: 10px 0px 0px 10%;
    display: flex;
    align-self: flex-start;
  }
  .title_chart {
    font-size: 16px;
    color: #4f4f4f;
  }
  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 10px;
    overflow: hidden;
  }

  .table_main_container {
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
    background-color: transparent;
  }
  .table_container {
    width: 100%;
    margin-bottom: 100px;
    margin-top: 0px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .box_period {
    min-width: auto;
  }
}
