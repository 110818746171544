
.machineUsage_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 25px;
    gap: 2vh;
}


.machineUsage_titleLabel{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 15px;
    /* identical to box height, or 47% */


    /* black FRA */

    color: #1A1B1E;
}

.machineUsage_smallTitleLabel{
    width: 50%;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* or 125% */


    /* txt FRA */

    color: #323539;
}

.machineUsage_footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.machineUsage_footerLabelColored, .machineUsage_footerLabel{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.25px;

    /* b */

    color: #20ACD9;
}

.machineUsage_footerLabel{
    font-weight: 400;
    color: #323539;
    opacity: 0.7;
}

.machineUsage_icon{
    left: 8.76%;
    right: 8.74%;
    top: 23.75%;
    bottom: 23.75%;

    /* VERDE TENNANT */
}

.machineUsageProgressBar{
    height: 8px;
    width: 100%;
    background-color: #EAF4F7;
    border-radius: 10px;
}
.machineUsageProgressBarFiller{
    height: 100%;
    background: #148AAD;
    border-radius: 10px;
}
.ContentLabelContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100%;
    gap: 2vh;
}
@media(max-width:900px){
    .ContentLabelContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width:auto;
        flex: 1;
        gap: 15px;
    }
}