.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vh;
}

.left_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.right_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}