.boxContainer {
	width: 100%;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	padding: 25px 40px;
	gap: 10px;
	background: #ffffff;
	border-radius: 25px;
}

.activityTitleLabel {
	text-align: center;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #09556F;
	margin-bottom: 10px;
}

.horHr {
	width: 100%;
	border: 1px solid #eaf4f7;
	margin-bottom: 30px;
}

.scoreContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.percentageLabel {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 10px;
	color: #5ba7ee;
}

.scoreLabel {
	font-family: Helvetica;
    font-size: 47px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
	color: #5ba7ee;
	margin-bottom: 10px;
}

@media (max-width: 900px) {
	.boxContainer {
		width: 100%;
		padding: 25px 0px;
	}

	.activityTitleLabel {
		font-size: 20px;
		line-height: 22px;
		margin-bottom: 10px;
	}

}
