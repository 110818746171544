.dataInput {
  border: none;
  background-color: #f2f6f6;
  border-radius: 0.5em;
  padding: 2vh;
  font-size: calc(6px + 1vmin);
  flex: 1;
}

.dataInputError {
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgb(168, 8, 8);
  transition: border-color 1s ease-in-out, box-shadow 1s ease-in-out;
  border: none;
  background-color: #f2f6f6;
  border-radius: 0.5em;
  padding: 2vh;
  font-size: calc(6px + 1vmin);
  flex: 1;
}

@media (max-width: 900px) {
  .dataInput {
    display: flex;
    flex: 1;
    font-size: 11px;
    width: 100%;
    height: 25px;
    color: black;
  }
  .container_input {
    display: flex;
    flex: 1;
    width: 100%;
  }
  .dataInputError {
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgb(168, 8, 8);
    transition: border-color 1s ease-in-out, box-shadow 1s ease-in-out;
    border: none;
    border-radius: 0.5em;
    padding: 2vh;
    font-size: 11px;
    width: 100%;
    height: 25px;
    flex: 1;
  }
}
