
.tableTitle_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
}

.imgBest_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px;
    
    width: 20px;
    height: 20px;
    
    /* VERDE TENNANT */
    
    background: #8BC93D;
    border-radius: 50%;
}

.imgWorst_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 7px;
    
    width: 20px;
    height: 20px;
    
    /* VERDE TENNANT */
    
    background: #FC8D41;
    border-radius: 50%;
}

.tableTitleLabel{
    width: 40%;
    font-size: 30px;
    font-weight: bold;
    margin-left: 0.5rem;

}

.tableTitleButton_container{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 3vh;
}

.compareGreen{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 236px;
    height: 42px;

    color: white;
    /* VERDE TENNANT */
    background-color: #8BC93D;
    font-weight: bold;
    border-radius: 10px;
    border: 0;
}

.compareOrange{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 236px;
    height: 42px;

    color: white;
    /* VERDE TENNANT */
    background-color: #FC8D41;
    font-weight: bold;
    border-radius: 10px;
    border: 0;
}

.downloadTable{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    
    width: 144.33px;
    height: 41.33px;
    
    color: white;
    font-weight: bold;
    border: 0;
    /* violet */
    
    background: #09556F;
    border-radius: 10px;
}
@media (max-width:900px) {
    
    .tableTitleLabel{
        width: 100%;
        font-size: 18px;
        font-weight: bold;
        margin-left: 0.5rem;

    }
}