.main_container {
    height: 166.98px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 20px 15px;
    gap: 1vh;
    background: #FFFFFF;
    border-radius: 25px;
}

/* SiteAMR CSS */

.header_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2vh;
}

.title_container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.imgTitle_container {
    position: relative;
    min-width: 25px;
    max-width: 25px;
}

.imgTitle {
    width: 100%;
}

.topSmallTitle {
    width: 17px;
    height: 12px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 11px;
    color: #323539;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 97px;
    height: 32px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #09556F;
}

.title::-webkit-scrollbar {
    display: none;
}

.hr {
    width: 100%;
    height: 2px;
    background-color: #EAF4F7;
    border: 0;
}

.body_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4vh;
}

.numberLabel {
    width: 100%;
    height: 35px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 34.03px;
    line-height: 34px;

    color: #20ACD9;
}

.machinesLabel {
    width: 100%;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #323539;
}

.footer_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.label2 {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    color: #20ACD9;
}

.footerLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    color: #323539;
}

@media(max-width:900px){
    .main_container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 20px 15px;
        gap: 5px;
        background: #FFFFFF;
        border-radius: 25px;
        min-width: 190px;
    }
    .body_container {
        display: flex;
        flex:1;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap:10px;
    }
    
    .numberLabel {
        display: flex;
        flex: 0.2;
        height: 35px;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 34.03px;
        line-height: 34px;
    
        color: #20ACD9;
    }
    .machinesLabel {
        display: flex;
        flex: 0.8;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #323539;
    }
}