.horizontalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5vh;
}

.verticalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5vh;
}

.iconContainer {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.iconBestContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px;

    width: 36px;
    height: 36px;

    background: #EAF4F7;
    border-radius: 100px;
}

.iconUnderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px;

    width: 36px;
    height: 36px;

    background: #FAF2EC;
    border-radius: 100px;
}


