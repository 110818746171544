.main_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.reduced_main_container{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1vh;
}

.containerStyles{
    height: 2px;
    width: 100%;
    background-color: #EAF4F7;
  }

  .fillerStyles{
    height: 100%;
    /* width: `${completed}%`; */
    /* background-color: bgcolor; */
    text-align: right;
  }

  .nonUsageFille{
    height: 100%;
    background-color: #EAF4F7;
  }

.labelStyles{
    padding: 5px;
    color: white;
    font-weight: bold;
  }
  
.bottomLabel{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  /* identical to box height, or 200% */
  text-align: center;
  width: 100%;
  letter-spacing: 0.25px;
  transform: translateX(-50%);
  /* black FRA */

  color: #1A1B1E;

  opacity: 0.5;
}

.labelsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.usageLabelStart{
font-family: 'Helvetica';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 8px;
/* identical to box height, or 67% */

letter-spacing: 0.25px;
/* transform: translate(-50%, -200%); */

display: flex;
    
transform: rotate(270deg) translate(25px, -19.5px);
position: absolute;
left: 0px;

/* violet */

color: #09556F;
}
.usageLabelEnd{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 8px;
  /* identical to box height, or 67% */
  
  letter-spacing: 0.25px;
  /* violet */
  
  color: #09556F;

  
  display: flex;
  transform: rotate(270deg) translate(25px, -19.5px);
  position: absolute;
  left: 100%;
  }
  .usageLabelEnd:before,
  .usageLabelStart:before{
    content:" ";
    margin-right: 3px;
    border: 3px solid transparent;
    border-left: 0px solid transparent;
    border-right: 3px solid #09556F;
    width: 1px;
    height: 1px;
    display: flex;
  }
  .usageLabelMANUAL{
    color: #ddba55 ;
    z-index: 1;
  }
  .usageLabelTRAINING{
      color: green;
      z-index: 2;
  }
  .usageLabelAUTONOMOUS{
      color: #148AAD;
      z-index: 2;
  }

  .progressBarFillerUsageMANUAL,
  .progressBarFillerUsageTRAINING,
  .progressBarFillerUsageAUTONOMOUS{
      height: 8px;
      background-color: #148AAD;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      position: absolute;
  }
  .progressBarFillerUsageMANUAL{
      background-color: #fdd663 ;
      z-index: 1;
  }
  .progressBarFillerUsageTRAINING{
      background-color: green;
      z-index: 2;
  }
  .progressBarFillerUsageAUTONOMOUS{
      background-color: #148AAD;
      z-index: 2;
  }

@media (max-width:900px) {

  .usageLabelEnd,
  .usageLabelStart{
    display: flex;
    transform: rotate(270deg) translate(25px, -19px);
  }
  .usageLabelEnd:before,
  .usageLabelStart:before{
    content:" ";
    margin-right: 3px;
    border: 3px solid transparent;
    border-left: 0px solid transparent;
    border-right: 3px solid #09556F;
    width: 1px;
    height: 1px;
    display: flex;
  }
}