.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vh;
}
.columnDataBenchmarkLabel {
    color: #00A0D0
}