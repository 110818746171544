.main_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3.5vh;
    margin: 0 2%;
    margin-bottom: 2%;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.verticalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.box_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 28px;

}

.box_subContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #FFFFFF;
    gap: 5vh;
    margin: 1.5rem 0;
}

.headButton {
    display: flex;
    padding: 12px 42px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    background: #FC8D41;
    color: white;
    border: 0;
    text-align: center;
    font-size: 18px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    cursor: pointer;
}

.link {
    text-decoration: none
}

.titleLabel {
    font-size: 34px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contactTitleLabel {
    font-size: 20px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contactValueLabel {
    color: var(--b, #20ACD9);
    font-size: 34px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.carouselContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
}

.img {
    width: 967px;
    padding: 15px 0;
}

.footerBtn {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: var(--violet, #09556F);
    border: 0;
    color: #FFF;
    font-size: 20px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px;
}
@media (max-width:900px) {
    .main_container{
        margin: 0 20px;
        margin-bottom:40px
    }
    .titlePageLabel{
        font-size: 30px;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .titleLabel{
        font-size: 21px;
    }
    .verticalContainer{
        gap: 20px!important;;
    }
    .contactTitleLabel{
        font-size: 14px;
    }
    .contactValueLabel {
        color: var(--b, #20ACD9);
        font-size: 18px;
    }
    .carouselContainer{
        border-radius: 25px;
    }
    .img {
        width: auto;
        display: flex;
        flex: 1;
        padding: 0px;
    }
    .footerBtn{
        font-size: 18px;
    }
    .footerBtn:active{
        opacity: 0.5;
    }
}