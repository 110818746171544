.boxContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 30px;
    gap: 3vh;

    background: #FFFFFF;
    border-radius: 25px;
}

.horizontalContainer {
    display: flex;
    flex-direction: row;
    gap: 2vh;
}

.boxSubContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2vh;
}
.activityTitleLabel {
    width: 100%;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    white-space: nowrap;
    /* violet */
    
    color: #09556F;
}

.titleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 15px;
    /* identical to box height, or 68% */
    
    
    /* black FRA */
    
    color: #1A1B1E;
}

.subtitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    /* or 200% */
    
    
    /* txt FRA */
    
    color: #323539;
}

.smallTitleLabelsProgressbar {

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    /* identical to box height, or 83% */
    
    
    /* violet */
    
    color: #09556F;
}

.smallSubtitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */
    
    
    /* violet */
    
    color: #09556F;
}

.horHr{
    width: 100%;
    border: 1px solid #EAF4F7;
}
.verHr{
    border: 1px solid #EAF4F7;
    height: 100%;
}

.scoreContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1vh;

}

.scoreLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */


    /* 2 */

    color: #5BA7EE;
}
@media (max-width:900px){
    .boxContainer {
        width: 100%;
        padding: 20px 0px;
    }
    .activityTitleLabel{
        white-space: unset;
        width: auto;
        padding: 0px 20px;
    }
    .boxSubContainer{
        padding: 0px 20px;
        flex-wrap: wrap;
    }    
}