.titleLabel,
.titleLabelBlue {
  width: 100%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 39px;
  color: #323539;
}

.titleLabelBlue {
  color: #20acd9;
}

.searchBtnContainer {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.searchBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 144.33px;
  height: 41.33px;
  border: 0;

  background: #09556f;
  border-radius: 10px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  color: #ffffff;
}

.downloadBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 144.33px;
  height: 41.33px;

  color: white;
  font-weight: bold;
  border: 0;
  /* violet */

  background: #09556f;
  border-radius: 10px;

  margin-top: 1rem;
  margin-left: 1rem;
}
.table_title_header {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vh;
  margin: 0 2%;
}
“ .subtitle_header {
  width: 100%;
  align-items: flex-start;
}

.select_container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  gap: 3vh;
  width: 100%;
  flex: 1;
}

.container_grafici {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 5%;
  gap: 2vh;
}

.box_grafici_1 {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 10%;
  flex: 0.3;
  padding: 10px 10px;
}

.box_grafici_2 {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 10%;
  flex: 0.4;
  padding: 10px 10px;
}

.boxContainer1 {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 10%;
  flex: 0.3;
  padding: 10px 10px;
}

.box_type {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.25;
  padding: 10px 10px;
  min-width: 250px;
}

.box_period {
  background-color: white;
  border: none;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
  padding: 10px 10px;
  min-width: 350px;
}

.box_sites {
  background-color: white;
  border: none;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
  padding: 10px 10px;
  min-width: 400px;
}

.box_reset_btn {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  height: 74px;
  padding: 10px 10px;
  min-width: 70px;
  max-width: 100px;
}

.box_chart {
  display: flex;
  flex-direction: column;
}

.title_chart {
  font-weight: bold;
  font-size: calc(3px + 1vmin);
}

.title_chart_container {
  margin-left: 10%;
}

.chart {
  display: flex;
  justify-content: flex-start;
}

.map_container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
}

.map {
  width: 98%;
  height: 369px;
  margin: 10px;
}

.button_main_container {
  width: 100%;
}
.button_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 3vh;
}

.outlined_green {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 42px;
  width: 293px;
  height: 42px;

  background: rgba(255, 255, 255, 0.87);
  /* VERDE TENNANT */
  border: 1px solid #8bc93d;
  color: #8bc93d;
  font-weight: bold;
  border-radius: 10px;
}
.outlined_orange {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 42px;

  width: 293px;
  height: 42px;
  padding: 12px 42px;
  background: rgba(255, 255, 255, 0.87);
  /* Arancio Tennant */

  border: 1px solid #fc8d41;
  color: #fc8d41;
  font-weight: bold;
  border-radius: 10px;
}
.outlined_orange:hover {
  background-color: #ffe7d7;
  cursor: pointer;
}
.outlined_green:hover {
  background-color: #f2ffe6;
  cursor: pointer;
}
.outlined_orange:active,
.outlined_green:active {
  opacity: 0.5;
}

.table_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4vh;
}

.table_subContainer {
  width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  .container {
    display: flex;
    flex: 1;
    width: 100%;
    margin: 0px;
    margin-bottom: 40px;
  }
  .box_period {
    min-width: auto;
  }
  .map_container {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
    flex-direction: column;
  }

  .map {
    display: flex;
    width: 100%;
    height: 250px;
    margin: 0px 20px;
  }
  .title_container_header {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    color: #323539;
  }
  .title_labelColored_header {
    color: #20acd9;
  }
  .filter_mobile {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2vh;
  }
  .filter_mobile:active {
    opacity: 0.4;
  }
  .filter_mobile_page_hide {
    overflow: hidden;
    display: flex;
    background-color: white;
    z-index: 1;
    margin-top: -3.5vh;
    height: 0px;
    width: 100%;
    align-items: center;
    border-radius: 10px;
    transition: all 0.3s ease-out;
  }
  .filter_mobile_page {
    overflow: hidden;
    display: flex;
    background-color: white;
    z-index: 1;
    width: 100%;
    height: 700px;
    border-radius: 10px;
    align-items: center;
    transition: all 0.3s ease-out;
    animation: cambiaStile 0.5s ease 0.5s forwards;
  }
  @keyframes cambiaStile {
    to {
      overflow: visible;
    }
  }
  .box_period {
    min-width: auto;
  }
  .downloadBtn {
    width: 60px;
    height: 38px;
  }
}
