.container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vh;
}

.left_container{
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.right_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media (max-width:900px) {
    .container{
        width:auto;
        flex: 1;
        gap: 0px;
    }
    .left_container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 0.1;
    }
    
    .right_container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 0.9;

    }
}