.boxContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 40px;
    gap: 49px;

    background: #FFFFFF;
    border-radius: 25px;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3vh;
}

.boxSubContainer {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3vh;
}


.titleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 15px;
    /* identical to box height, or 47% */


    /* black FRA */

    color: #1A1B1E;
}

.subtitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    /* or 167% */


    /* txt FRA */

    color: #323539;
}

.smallTitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
}

.smallSubtitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */


    /* black FRA */

    color: #1A1B1E;
}

.horHr{
    width: 100%;
    border: 1px solid #EAF4F7;
}
.verHr{
    border: 1px solid #EAF4F7;
    height: 100%;
}

.scoreContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1vh;

}

.scoreLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */


    /* 2 */

    color: #5BA7EE;
}
@media(max-width:900px){
    .boxContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 49px;
    
        background: #FFFFFF;
        border-radius: 25px;
    }
    .boxSubContainer {
        width: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap:20px;
        padding: 20px;
        
    }
    .titleLabelsProgressbar {
        font-size: 30px;
    }
    

}