.titleLabel {
  width: 100%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 39px;
  color: #323539;
}

.container {
  height: 100%;
  background-color: white;
  width: 30vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: fixed; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 3%; */
}

.logo_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2rem;
}

.logo {
  /* height: 20vmin; */
  width: 15vmin;
}

.sidebar_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-top: 2rem;
}

.navlink {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 0 0 0 2rem;
}

.navlinkActive {
  text-decoration: none;
  color: black;
  font-weight: bold;
  background: #f2f7f7;
  border-radius: 12px 0px 0px 12px;
  margin: 0 0 0 2rem;
}

.navlink_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8em;

  /* width: 1vmin; */
}

.link_icon {
  width: 2vmin;
}

.link_iconActive {
  width: 2vmin;
  filter: invert(57%) sepia(97%) saturate(965%) hue-rotate(157deg)
    brightness(90%) contrast(89%);
}

.link_text {
  margin-left: 8%;
  font-size: calc(6px + 1vmin);
  /* width: 2vmin; */
}

.link {
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 15px;
}

.subnav_container {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
}

.subnavlink {
  text-decoration: none;
  font-weight: lighter;
  color: black;
  padding: 0 0 0 2rem;
  margin: 0 0 0 20%;
}

.subnavlinkActive {
  text-decoration: none;
  color: black;
  font-weight: bold;
  background: #f2f7f7;
  padding: 0 0 0 2rem;
  margin: 0 0 0 20%;
  border-radius: 12px 0px 0px 12px;
}

.footer {
  margin-top:2%;
  bottom: 0%;
  width: 30vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}