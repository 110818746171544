.customTooltip {
    border: solid grey 1px;
    background-color: #f2f6f6;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: bold;
}

.labelX {

}

.labelY {
}
