.containerCustomLoader{
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
}

.containerSearchCustomLoader {
    margin-left: 2vw;
}

.customLoader{
    width: 40px;
    height: 40px;
    border: 10px solid #00A0D0;
    border-right: 10px solid transparent;
    border-radius: 360px;
    animation:rotation 2s ease-in-out infinite;
}

.searchCustomLoader{
    width: 20px;
    height: 20px;
    border: 10px solid #00A0D0;
    border-right: 10px solid transparent;
    border-radius: 360px;
    animation:rotation 2s ease-in-out infinite;
}

@keyframes rotation{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(720deg);
    }
}