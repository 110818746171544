.boxContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3vh;
    padding: 20px 0;
    background: #FFFFFF;
    border-radius: 25px;
}

.boxSubContainer {
    width: 95%;
    display: flex;
    flex-direction: row;
align-items: center;
    gap: 3vh;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3vh;
}

.verticalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3vh;
}

.gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4vh;
}

.gridToolsenseContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4vh;
}

.rankBestNumberLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 62px;
    line-height: 47px;
    /* identical to box height, or 75% */


    /* VERDE TENNANT */

    color: #8BC93D;
}

.rankUnderNumberLabel{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 62px;
    line-height: 47px;
    /* identical to box height, or 75% */


    /* VERDE TENNANT */

    color: #FC8D41;
}

.rankLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */


    /* txt FRA */

    color: #323539;
}



.machineIcon {
    width: 129.19px;
    height: 96.16px;
}

.rinLabel,
.siteLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 11px;
    /* identical to box height */


    /* txt FRA */

    color: #323539;
}

.rinValueLabel,
.siteValueLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */


    /* violet */

    color: #09556F;
}

.propertyLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 11px;
    /* identical to box height */


    /* txt FRA */

    color: #323539;
}

.propertyValue {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */


    /* txt FRA */

    color: #323539;
}

.verHr {
    border: 2px solid #EAF4F7;
    height: 100%;
}
@media(max-width:900px){
    .boxContainer{
        width: 1100px;
    }
}