.titleLabel,
.titleLabelBlue {
  width: 100%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 39px;
  color: #323539;
}

.titleLabelBlue {
  color: #20acd9;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
  margin: 0 2%;
}

.subtitle_header {
  width: 100%;
  align-items: flex-start;
}

.select_container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-items: center; */
  gap: 3vh;
  width: 100%;
  flex: 1;
}

.searchBtn_container {
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-right: auto;
}

.searchBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 144.33px;
  height: 41.33px;
  border: 0;

  background: #09556f;
  border-radius: 10px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  color: #ffffff;
}

.container_grafici {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 5%;
  gap: 2vh;
}

.box_grafici_1 {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 10%;
  flex: 0.3;
  padding: 10px 10px;
}

.box_grafici_2 {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 10%;
  flex: 0.4;
  padding: 10px 10px;
}

.boxContainer1 {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 10%;
  flex: 0.3;
  padding: 10px 15px;
}

.box_period {
  background-color: white;
  border: none;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100%; */
  flex: 0.3;
  min-width: 350px;
  padding: 10px 15px;
}

.box_type {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.25;
  padding: 10px 15px;
  min-width: 250px;
}

.box_sites {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 10vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.25;
  padding: 10px 15px;
  min-width: 250px;
}

.box_reset_btn {
  background-color: white;
  border: none;
  border-radius: 1em;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  height: 74px;
  padding: 10px 15px;
  min-width: 70px;
  max-width: 100px;
}

.box_chart {
  display: flex;
  flex-direction: column;
}

.title_chart {
  font-weight: bold;
  font-size: calc(3px + 1vmin);
}

.title_chart_container {
  margin-left: 10%;
}

.chart {
  display: flex;
  justify-content: flex-start;
}

.map_container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
  margin-bottom: 1em;
}

.map {
  width: 98%;
  height: 369px;
  margin: 10px;
}

@media (max-width: 900px) {
  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2vh;
    margin: 0px 20px;
  }
  .title_mobile {
    display: flex;
    flex-direction: row;
  }
  .title_mobile_text {
    font-size: 23px;
    font-weight: 700;
    color: #20acd9;
  }
  .title_mobile_name {
    font-size: 23px;
    font-weight: 700;
    margin-left: 5px;
  }
  .filter_mobile {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2vh;
  }
  .filter_mobile:active {
    opacity: 0.4;
  }
  .map_container {
    border-radius: 10px;
    margin: 0px;
    order: 1;
  }
  .map {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    margin: 0px;
  }
  .container_grafici {
    order: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0px 0px 50px 0px;
    gap: 20px;
  }
  .box_grafici_1 {
    background-color: white;
    border: none;
    border-radius: 10px;
    /* height: 10vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: unset;
    min-width: unset;
    flex: 0.3;
    padding: 0px 0px;
  }

  .box_grafici_2 {
    background-color: white;
    border: none;
    border-radius: 10px;
    /* height: 10vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: unset;
    min-width: unset;
    flex: 0.4;
    padding: 0px 0px;
  }
  .box_chart {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  .title_chart_container {
    margin: 10px 0px 0px 10%;
    display: flex;
    align-self: flex-start;
  }
  .title_chart {
    font-size: 16px;
    color: #4f4f4f;
  }
  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 10px;
  }
  .filter_mobile_page_hide {
    overflow: hidden;
    display: flex;
    background-color: white;
    z-index: 1;
    margin-top: -3.5vh;
    height: 0px;
    width: 100%;
    align-items: center;
    border-radius: 10px;
    transition: all 0.3s ease-out;
  }
  .filter_mobile_page {
    overflow: hidden;
    display: flex;
    background-color: white;
    z-index: 1;
    width: 100%;
    /*height: 700px;*/
    border-radius: 10px;
    align-items: center;
    animation: cambiaStile 0.5s ease 0.5s forwards;
    transition: all 0.3s ease-out;
  }
  @keyframes cambiaStile {
    to {
      overflow: visible;
    }
  }
  .box_period {
    min-width: auto;
  }
}
