.to_hide_in_report {
}
.hidden {
	display: none !important;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 4vh;
	margin: 0 2%;
	margin-bottom: 2%;
}

.horizontalContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 3vh;
}

.verticalContainerMachineTable,
.verticalContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2vh;
}

.filterbar_main_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 0 20px 0;
	border-radius: 25px;
	background: #ffffff;
}

.pdf_page {
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	gap: 2vh;
}

.loading_wheel {
	display: flex;
	background-color: #09556f;
	border-radius: 360px;
	border: 4px solid white;
	border-right: 4px solid #09556f;
	width: 15px;
	height: 15px;
	justify-content: center;
	align-items: center;
	animation: rotate 2s infinite linear;
}

@keyframes rotate {
	0% {
		rotate: 0deg;
	}
	50% {
		rotate: 180deg;
	}
	100% {
		rotate: 360deg;
	}
}

.filterBar_container {
	width: 95%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 3vh;
	background: #ffffff;
}

.filterBar_header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.filterLabel {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 25px;

	/* txt FRA */

	color: #323539;

	opacity: 0.8;
}

.filterBar_body {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	gap: 2vh;
}

.filterBar_footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 3vh;
	align-items: center;
}

.filterBar_footerLabel {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	/* identical to box height */

	/* txt FRA */

	color: #323539;
}

.select_container {
	width: 100%;
}

.resetFilters_btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 10px;
	gap: 8px;

	background: #f2f7f7;
	border-radius: 20px;
	border: 0;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	/* identical to box height */

	/* txt FRA */

	color: #323539;
}

.resetFiltersIcon {
	width: 10px;
	height: 10px;
}

.downloadContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 14px;

	width: 236px;

	background: #ffffff;
	border-radius: 25px;
}

.downloadLabel {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;

	/* txt FRA */

	color: #323539;

	opacity: 0.7;
}

.downloadBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;

	width: 144.33px;
	height: 41.33px;
	border: 0;
	/* violet */

	background: #09556f;
	border-radius: 10px;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;

	color: #ffffff;
}

.searchBtnContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.searchBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;

	width: 144.33px;
	height: 41.33px;
	border: 0;

	background: #09556f;
	border-radius: 10px;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;

	color: #ffffff;
}

.map_container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	background-color: white;
	flex-direction: column;
	margin-bottom: 1em;
}

.map {
	width: 98%;
	height: 369px;
	margin: 10px;
}

.titleLabel {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 39px;
	/* identical to box height */

	/* txt FRA */

	color: #323539;
}

.box_grafico {
	background-color: white;
	width: 100%;
	border: none;
	border-radius: 1em;
	/* height: 10vh; */
	display: flex;
	min-height: 100%;
	min-width: 400px;
	flex: 0.3;
	padding: 20px 45px;
}

.title_chart {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 125.99%;
	/* or 25px */
	white-space: nowrap;

	/* black FRA */

	color: #1a1b1e;

	opacity: 0.7;
	margin-right: 1rem;
}

.button_main_container {
	margin-top: 80px;
	width: 100%;
}

.button_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 3vh;
}

.outlined_green {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 12px 42px;
	width: 293px;
	height: 42px;

	background: rgba(255, 255, 255, 0.87);
	/* VERDE TENNANT */
	border: 1px solid #8bc93d;
	color: #8bc93d;
	font-weight: bold;
	border-radius: 10px;
}

.outlined_orange {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 12px 42px;

	width: 293px;
	height: 42px;
	padding: 12px 42px;
	background: rgba(255, 255, 255, 0.87);
	/* Arancio Tennant */

	border: 1px solid #fc8d41;
	color: #fc8d41;
	font-weight: bold;
	border-radius: 10px;
}

.outlined_orange:hover {
	background-color: #ffe7d7;
	cursor: pointer;
}

.outlined_green:hover {
	background-color: #f2ffe6;
	cursor: pointer;
}

.outlined_orange:active,
.outlined_green:active {
	opacity: 0.5;
}

.title_chart_container {
	display: flex;
	width: 100%;

	flex-direction: row;
	justify-content: space-between;
}

.subtitle_chart_container {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2vh;
}

.charts_container {
	display: flex;
	width: 80%;
	flex-direction: row;
	gap: 3vh;
}

.box_chart {
	display: flex;
	flex-direction: column;
}

.selectOverview {
	border: none;
	background-color: #f2f6f6;
	border-radius: 0.5em;
	padding: 1vh;
	width: 100px;
	font-size: calc(4px + 1vmin);
}

@media (max-width: 900px) {
	.container {
		margin: 0 20px;
		margin-bottom: 40px;
		overflow: hidden;
	}

	.title_container_header {
		display: flex;
		flex: 1;
		width: 100%;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		margin-bottom: 10px;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 700;
		font-size: 23px;
		color: #323539;
	}

	.horizontalContainer {
		gap: 0px;
		justify-content: space-between;
		flex-direction: row;
	}

	.filterBar_container {
		width: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 1vh;
		background: transparent;
	}

	.filter_mobile {
		display: flex;
		align-items: flex-start;
		margin-bottom: 2vh;
	}

	.filter_mobile:active {
		opacity: 0.4;
	}

	.filterBar_body_hide {
		overflow: hidden;
		background-color: white;
		gap: 20px;
		z-index: 1;
		display: flex;
		flex-direction: column;
		height: 0px;
		width: 100%;
		border-radius: 10px;
		align-items: center;
		transition: all 0.3s linear;
		margin-top: -3.5vh;
	}

	.filterBar_header {
		width: auto;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	}

	.filterBar_body {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 10px;
		padding: 10px 0px;
		background-color: white;
		z-index: 1;
		height: 500px;
		width: 100%;
		border-radius: 10px;
		align-items: center;
		transition: all 0.3s linear;
		animation: cambiaStile 0.5s ease 0.5s forwards;
	}

	@keyframes cambiaStile {
		to {
			overflow: visible;
		}
	}
	.select_container {
		display: flex;
		flex: 1;
		width: 100%;
	}

	.resetFilters_btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 80%;
		margin: 0px 10px 20px 10px;
		padding: 15px 0px;
		gap: 8px;
		background: #f2f7f7;
		border-radius: 5px;
		border: 1px solid #20acd9;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #323539;
	}

	.downloadBtn {
		gap: 0px;
		background-color: transparent;
		width: auto;
		height: auto;
		border: 0;
		/* violet */
		border-radius: 5px;
	}

	.downloadBtn:active {
		opacity: 0.4;
	}

	.map_container {
		border-radius: 10px;
		margin: 0px;
	}

	.map {
		width: 100%;
		height: 250px;
		border-radius: 10px;
		margin: 0px;
		overflow: hidden;
	}

	.labelAllMachineContainer {
		display: flex;
		flex: 1;
		flex-direction: row;
		gap: 10px;
		align-items: center;
	}

	.labelAllMachine {
		font-weight: 700;
		font-size: 16px;
	}

	.titleLabel {
		font-size: 18px;
		font-weight: 700;
		line-height: 20px;
	}

	.verticalContainer {
		width: auto !important;
		margin: 0px !important;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2vh;
	}

	.verticalContainerMachineTable {
		flex: 1;
		overflow: auto;
		align-items: flex-start;
	}

	.box_grafico {
		background-color: white;
		border: none;
		border-radius: 10px;
		/* height: 10vh; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: unset;
		min-width: unset;
		flex: 1;
		padding: 0px 0px;
	}

	.box_chart {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex: 1;
	}

	.title_chart_container {
		margin: 10px 0px 0px 10%;
		display: flex;
		align-self: flex-start;
	}

	.title_chart {
		font-size: 16px;
		color: #4f4f4f;
	}

	.chart {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		font-size: 10px;
		overflow: hidden;
	}
}
