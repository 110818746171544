.boxContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px 40px;
    gap: 49px;

    background: #FFFFFF;
    border-radius: 25px;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3vh;
}

.boxSubContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3vh;
}
.activityTitleLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    /* or 133% */


    /* violet */

    color: #09556F;
}

.legend_main_Container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 2rem;
}

.legendContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 2vh;

}

.legendLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1A1B1E;

    opacity: 0.7;
}

.legendAuton,
.legendMan {
    width: 8px;
    height: 8px;
    border-radius: 10px;
}

.legendAuton {
    background: #09556F;
}

.legendMan {
    background: #EAF4F7;
    border: 0.5px solid #323539;
}

.titleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 15px;
    /* identical to box height, or 47% */


    /* black FRA */

    color: #1A1B1E;
}

.subtitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */


    /* txt FRA */

    color: #323539;
}

.smallTitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    /* identical to box height, or 83% */


    /* black FRA */

    color: #1A1B1E;
}

.smallSubtitleLabelsProgressbar {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */


    /* black FRA */

    color: #1A1B1E;
}

.horHr{
    width: 100%;
    border: 1px solid #EAF4F7;
}
.verHr{
    border: 1px solid #EAF4F7;
    height: 100%;
}

.scoreContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1vh;

}

.scoreLabel {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */


    /* 2 */

    color: #5BA7EE;
}
@media (max-width:900px){
    .boxContainer {
        width: 100%;
        padding:25px 0px;
    }
    .legend_main_Container {
        width:auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        margin-bottom: 20px;
    }
    .boxSubContainer {
        gap: 1.5vh;
    }
    .activityTitleLabel {
        font-size: 20px;
        line-height: 22px;
    }
    .smallSubtitleLabelsProgressbar {
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        /* identical to box height, or 100% */
    
    
        /* black FRA */
    
        color: #1A1B1E;
    }
}