.downloadBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    
    width: 144.33px;
    height: 41.33px;
    
    color: white;
    font-weight: bold;
    border: 0;
    /* violet */
    
    background: #09556F;
    border-radius: 10px;
}
.loading_wheel{
    display: flex;
    background-color: #09556f;
    border-radius: 360px;
    border:4px solid white;
    border-right: 4px solid #09556f;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    animation: rotate 2s infinite linear ;
}
@keyframes rotate{
    0%{rotate: 0deg;}
    50%{rotate: 180deg;}
    100%{rotate: 360deg;}
}