.likesContainer {
  width: 90%;
  border: 1px solid #20ACD9;
  border-radius: 21px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
}

.smilesContainer {
  padding-top: 5%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.smilesContainer img:hover {
  border-radius: calc(100%/2);
    -moz-border-radius: calc(100%/2);
    -webkit-border-radius: calc(100%/2);
}

.smilesContainer a:nth-child(1) img:hover {
  background-color: #77DD77;
}

.smilesContainer a:nth-child(2) img:hover {
  background-color: #BBDA3B;
}

.smilesContainer a:nth-child(3) img:hover {
  background-color: gold;
}

.smilesContainer a:nth-child(4) img:hover {
  background-color: orange;
}

.smilesContainer a:nth-child(5) img:hover {
  background-color: red;
}

.footerLabel {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 9px;
  text-decoration-line: underline;
  margin-bottom: 10px;
  color: #20ACD9;
}