.titleLabel,
.titleLabelBlue {
  width: 100%;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 39px;
  color: #323539;
}

.titleLabelBlue {
  color: #20acd9;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3.5vh;
  margin: 0 2%;
  margin-bottom: 2%;
}

.horizontalContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2vw;
}

.verticalContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}

.box_container {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  gap: 10vh;
  padding: 30px 0;
}

.box_subContainer {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #ffffff;
  gap: 10vh;
}

.label {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.7;
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
}

@media (max-width: 900px) {
  .container {
    align-items: normal;
    flex: 1;
    margin: 0px 20px;
  }
  .titleBlue {
    font-size: 30px;
    font-weight: 700;
    font-family: "Helvetica";
  }
  .box_container {
    width: auto;
    display: flex;
    flex: 1;
    align-items: normal;
    border-radius: 15px;
    min-height: 0px;
    gap: 20px;
    padding: 0px 0px;
  }
  .box_subContainer {
    display: flex;
    width: auto;
    flex: 1;
    align-items: normal;
    border-radius: 15px;
    min-height: 0px;
    gap: 30px;
    padding: 40px 20px;
    height: auto;
  }

  .verticalContainer {
    width: auto !important;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: normal;
    gap: 30px;
  }
  .label {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}
