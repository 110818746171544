.Select {
    border: none;
    background-color: #F2F6F6;
    border-radius: 0.5em;
    padding: 1vh;
    width: 100%;
    font-size: calc(4px + 1vmin);
}
@media (max-width:900px){
    .Select {
        border: none;
        background-color: #F2F6F6;
        border-radius: 0.5em;
        width: 100%;
        font-size: 11px;
        color: black;
        padding:5px
    }
    .container_select{
        display:flex;
        flex:1;
        width:100%;
    }
}