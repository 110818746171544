.main_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3.5vh;
    margin: 0 2%;
    margin-bottom: 2%;
}

.horizontalContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.verticalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.box_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 28px;

}

.box_subContainer {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: #FFFFFF;
    gap: 5vh;
    margin: 1.5rem 0;
}

.titleLabel,
.titleLabelOrange {
    font-size: 34px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.titleLabelOrange {
    color: #FC8D41;
}

.subtitleLabel {
    color: var(--txt-fra, #323539);
    font-size: 24px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.table_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vh;
    background: #FFFFFF;
    border-radius: 25px;
    margin-bottom: 1rem;
}

.oldestNotificationBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
    border-radius: 5px;
    border: 0;
    padding: 10px 20px;
    background: var(--grigio-fra, #F2F6F6);
}
@media (max-width:900px) {
    .main_container{
        margin: 0px 20px 40px;
    }
    .notificationContainerMobile{
        width: 100%;
        gap:3.5vh;
        align-items: flex-start;
    }
    .notificationTitleContainer{
        display: flex;
        flex: 1;
        flex-direction: row;
        gap:8px;
        margin: 8px 0px;
    }
    .notificationIcon{
        width: 15px;
        height: 15px;        
    }
    .notificationLabel{
        font-size: 30px;
        font-weight: 700;
    }
    .notificationMain_Container{
        padding: 20px;
        border-radius: 15px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
    }
    .notificationContainer{
        width: 100%;
        display: flex;
        gap:8px;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .hr{
        width: 90%;
        border: 1px solid #F2F6F6;
        margin: 0.5rem 0;
    }
}