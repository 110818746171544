/* .ContainerFilterBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex: 1;
} */

.ContainerFilterBar1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 100%;
    grid-gap: 20px;
}

.ContainerFilterBar2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
    grid-gap: 20px;
}

.ContainerFilterBar3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 100%;
    grid-gap: 20px;
}

.ContainerFilterBar4 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 100%;
    grid-gap: 20px;
}

.line {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    flex: 1;
}

/* .label_select {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    flex: 1;
} */

.LabelButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
}

.ResetFilter_btn {
    min-width: 30px;
    min-height: 30px;
    border: none;
    background-color: #f2f6f6;
    border-radius: 0.5em;
}

.container_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleBox {
    font-size: calc(6px + 1vmin);
    font-weight: bold;
}

.box_period {
    background-color: white;
    border: none;
    border-radius: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-height: 100%; */
    flex: 0.3;
    padding: 10px 10px;
}

@media (max-width: 900px){
    .ContainerFilterBar{
        display: flex;
        flex-direction: column;
        width: 80%;
        height: auto;
        align-items: normal;
        justify-content: center;
        flex: 1;
        margin: 0px 10px;
    }
}
