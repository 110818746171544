.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4vh;
	margin: 0 2%;
}

.box_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	background: #ffffff;
}

.box_subContainer {
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	gap: 2.5vh;
	margin: 1rem 0;
}

.charts_container {
	display: flex;
	width: 80%;
	flex-direction: row;
	gap: 3vh;
}

.box_chart {
	display: flex;
	flex-direction: column;
}
.box_grafico {
	background-color: white;

	border: none;
	border-radius: 1em;
	/* height: 10vh; */
	display: flex;
	min-height: 100%;
	min-width: 400px;
	flex: 0.3;
	padding: 1em 1em;
}

.title_chart,
.subtitle_chart {
	width: 100%;
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 125.99%;
	/* or 25px */

	/* black FRA */

	color: #1a1b1e;

	opacity: 0.7;
}

.subtitle_chart {
	font-weight: 400;
	font-size: 14px;
}

.title_chart_container {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}

.subtitle_chart_container {
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 2vh;
}

.charts_container {
	display: flex;
	width: 80%;
	flex-direction: row;
	gap: 3vh;
}

.box_chart {
	display: flex;
	flex-direction: column;
}


.titleLabel,
.titleLabelBlue {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 39px;
	color: #323539;
}

.titleLabelBlue {
	color: #20acd9;
}

.subHeader_labelColored {
	color: #20acd9;
}

.subHeader_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 5px;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #323539;
}

.inputContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 2vw;
}

.inputContainer > * {
	display: flex;
	align-items: center;
	justify-content: center;
}

.label {
	font-family: Helvetica;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	opacity: 0.7;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 10px;
}

.filterbar_main_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 0 20px 0;
	border-radius: 25px;
	background: #ffffff;
}

.filterBar_container {
	width: 95%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background: #ffffff;
}

.filterBar_header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.filterLabel {
	width: 44px;
	height: 18px;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	color: #323539;
	opacity: 0.8;
}

.dateFilterLabel {
	margin-top: 10px;
	height: fit-content;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: bold;
	font-size: small;
	line-height: 18px;
	color: #323539;
	opacity: 0.8;
}

.resetFilters_btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 10px;
	gap: 8px;
	background: #f2f7f7;
	border-radius: 20px;
	border: 0;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	color: #323539;
}

.resetFiltersIcon {
	width: 10px;
	height: 10px;
}

.filterBar_body {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2vh;
}

.select_container {
	width: 100%;
}

.searchBtn_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 2vh 0;
	margin-right: auto;
}

.searchBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 144.33px;
	height: 41.33px;
	border: 0;
	background: #09556f;
	border-radius: 10px;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	color: #ffffff;
	margin-top: 15px;
}

/* Legend */
/* 
.main_legendContainer {
	width: 100%;
	border-radius: 25px;
	background: #ffffff;
}

.legendContainer {
	max-width: 95%;
	font-size: small;
	margin: 15px;
}

.gridLegend {
	display: grid;
	grid-template-columns: 1fr 3fr 1fr 3fr;
	grid-gap: 10px;
}

.headerLegendRow {
	font-weight: bold;
	text-align: center;
	margin-bottom: 10px;
	color: #585d64;
}

.metricLabel {
	font-weight: bold;
	color: #09556f;
} */

/* Table */

.table_container {
	width: 100%;
	margin-bottom: 6rem;
	margin-top: 1rem;
	border-radius: 25px;
}

.labelWithIcon {
	width: 25%;
	display: flex;
	align-items: center;
}

.iconWithTooltip {
	position: relative;
	display: inline-block;
	margin-left: 8px;
	margin-top: 10px;
}

.infoIcon {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	border: 0.7px solid #323539;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: bold;
	font-size: small;
	line-height: 18px;
	color: #323539;
	opacity: 0.7;
}

.tooltipText {
	height: 30px;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: bold;
	font-size: small;
	color: #585d64;
	visibility: hidden;
	width: 20vw;
	background-color: #ffffff;
	text-align: center;
	border-radius: 5px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0.7;
	transition: opacity 0.3s;
	border: 0.1px solid #585d64;
}

.iconWithTooltip:hover .tooltipText {
	visibility: visible;
	opacity: 1;
}

@media (max-width: 900px) {
	.container {
		margin: 0px 20px;
	}

	.box_subContainer {
		flex: 1;
		width: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: normal;
		justify-content: center;
		background: #ffffff;
		gap: 0px;
		margin: 20px 20px;
	}

	.inputContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-bottom: 20px;
	}

	.filterBar_container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		background: transparent;
	}

	.resetFilters_btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 80%;
		margin: 0px 10px 20px 10px;
		padding: 15px 0px;
		gap: 8px;
		background: #f2f7f7;
		border-radius: 5px;
		border: 1px solid #20acd9;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #323539;
	}

	.filterBar_body {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 10px;
		padding: 10px 0px;
		background-color: white;
		z-index: 1;
		height: 300px;
		width: 100%;
		border-radius: 10px;
		align-items: center;
		transition: all 0.3s linear;
		animation: cambiaStile 0.5s ease 0.5s forwards;
	}

	.select_container {
		display: flex;
		flex: 1;
		width: 100%;
	}

	.searchBtn_container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 2% 0px;
		margin-right: auto;
	}

	.title_labelColored {
		color: #20acd9;
	}

	.title_container {
		display: flex;
		flex: 1;
		width: 100%;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		margin-bottom: 10px;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 700;
		font-size: 23px;
		color: #323539;
	}

	.box_period {
		margin: 0px 10px;
	}

	.table_container {
		width: 100%;
		margin-bottom: 0px;
		margin-top: 0px;
		display: flex;
		flex-direction: column;
	}

	.charts_container {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		margin: 0;
		gap: 20px;
	}
	.box_grafico {
		background-color: white;
		border: none;
		border-radius: 10px;
		/* height: 10vh; */
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: unset;
		min-width: unset;
		flex: 0.3;
		padding: 0 0;
	}
	.box_chart {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
	.title_chart_container {
		margin: 10px 0 0 10%;
		display: flex;
		align-self: flex-start;
	}
	.title_chart {
		font-size: 16px;
		color: #4f4f4f;
	}
	.chart {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		font-size: 10px;
		overflow: hidden;
	}
}
