.recharts-default-legend {
    display: none;
}

.customTooltip {
    border: solid grey 1px;
    background-color: #f2f6f6;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: bold;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.labelX {
    padding: 10px;
}

.labelY {
    padding: 10px;
}

.square {
    margin-left: 10px;
    width: 1em;
    height: 1em;
    border: solid black 1px;
}

.squareColorPrimary {
    background-color: #09556F
}

.squareColorSecondary {
    background-color: #FC8D41
}
