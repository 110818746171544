.verticalContainer{

    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.verticalProgressbarContainer{
    height: 8px;
    width: 100%;
    background-color: #EAF4F7;
    border-radius: 10px;
}

.verticalProgressbarFiller{
    height: 100%;
    background: #5BA7EE;
    border-radius: 10px;
}

/* Horizontal progress bar è quella verticale */

.horizontalContainer{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 1vh;

}
.horizontalLabelsContainer{

    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2vh;
}

.horizontalProgressbarContainer{
    width: 11px;
    height: 100%;
    background: #148AAD;
    
    border-radius: 0px 5px ;
}

.horizontalProgressbarFiller{
    background-color: #EAF4F7;
    border-radius: 0px 5px 0 0;
}
@media(max-width:900px){
    .horizontalContainer{
        width: 45%;
        word-break: break-all;
    }
}