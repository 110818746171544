/* .TopContainer {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.table {
    display: grid;
    border-collapse:
        collapse;
    min-width: 100%;
    grid-template-columns: repeat(4, minmax(150px, 3fr));
}

.StyledTableContainer thead,
.StyledTableContainer tbody,
.trTable {
    display: contents;
}

.thTable,
.StyledTableContainer td {
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.thTable {
    text-align: left;
    font-weight: normal;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.AddButton {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
} */

/* .App {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.tableDiv {
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    height: 200px;
    border-collapse: collapse;
    margin-bottom: 1rem;
}
.thFirst,.thUsagePeriod,
.th {
    width: 100px;
    background: white;
    padding: 15px 5px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #323539;
    opacity: 0.7;
    cursor: pointer;
}
.thUsagePeriod{
    width: 300px;
}
.td {
    text-align: center;
    padding: 15px 5px;
    color: #000;
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    opacity: 0.7;
}

.td.unread {
    font-weight: bold;
}

.tdFirst {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 21px;
    /* identical to box height, or 62% */

    text-align: center;

    /* VERDE TENNANT */

    color: #8BC93D;

    opacity: 0.7;
}
.trAction,
.tr {
    background-color: white;
}

.trAction:nth-child(odd),
.tr:nth-child(odd) {
    background-color: #EAF4F7;
}

.trBadAutoUsage > td {
    font-weight: bold;
}

/* .trBadAutoUsage:nth-child(odd) {
    background-color: #EAF4F7;
} */

/* .trBadAutoUsage:nth-child(odd) > td {
    font-weight: bold;
} */

.button1 {

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

}

.button2 {

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

}

.footer_container {
    width: 100%;
    display: flex;
    flex:1;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    overflow: visible;
}

.pagesButtons_container {
    display: flex;
    flex:0.8;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vh;
}

.footerButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    width: 63.33px;
    height: 51.33px;

    background: #FFFFFF;
    border-radius: 6px;
    border: 0;
    color: #148AAD;
    font-weight: bold;
}

.rowsButton_container {
    display: flex;
    flex: 0.2;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.rowsButtonLabel {
    flex:0.8;
    font-size: 14px;
}



.textPage {
    display: flex;
    justify-content: flex-end;

}

.provaDiv {
    width: 100%;
}

.rowDetail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    background: #DDEBEF;
    border: 0;
}

.rowDetail_container {
    width: 100%;
}

.iconContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 5px 5px 8px;
    width: 33px;
    height: 33px;
    background: rgba(0, 160, 208, 0.1);
    border-radius: 100%;
    filter: invert(41%) sepia(53%) saturate(702%) hue-rotate(147deg) brightness(99%) contrast(94%);
}


.progressBarContainer {
    background: #4EC1E3;
    border-radius: 10px;
    width: 90%;
    height: 8px;
}

.progressBarFiller {
    height: 8px;
    border-radius: 10px;
}


.alertNotificationIconContainer {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 29px;
    background: rgba(0, 160, 208, 0.10);
}

.positiveNotificationIconContainer {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 29px;
    background: rgba(139, 201, 61, 0.10);

}

.negativeNotificationIconContainer {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 29px;
    background: rgba(252, 141, 65, 0.10)
}

.checkboxOn {

}
.checkboxOff {

}
@media (max-width: 900px){
    .table {
        height: auto;
        flex-direction: column;
        border-collapse: collapse;
        margin-bottom: 0px;
        display: flex;
    }

    .tableDiv{
        display: flex;
        flex:1;
        flex-direction: column;
    }
    .trAction,
    .tr,
    .trBadAutoUsage{
        height: 60px;
    }
    .trHead, .trHeadAction,
    .table thead tr{
        height: 50px;
        background: white;
    }
    .trHeadAction{
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 0px;
        width: 100%;
    }
    .trAction{
        min-width: 0px;
        max-width: none;
        width: 100%;
        display: flex;
        align-items: center;

    }

    .thFirst, 
    .thAction,
    .thUsagePeriod,
    .th {
        width: auto;
        min-width: 100px;
        background: white;
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 12px;
        overflow: hidden;
        padding:0px 10px;
        /* txt FRA */
        color: #323539;
        opacity: 0.7;
    }
    .thUsagePeriod{
        min-width: 195px;
        padding: 0px 20px;
        
    }
    .thFirst{
        background-color: white;
        min-width: 40px;
        opacity: 1;
    }
    .thAction{
        min-width: 0px;
        width: 100%;
        flex: 1;
    }
    

    .tdFirst,
    .tdAction,
    .td {
        text-align: center;
        padding:0px 10px;
        color: #000;
        font-family: 'Helvetica';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        min-width: 100px;
        overflow: hidden;
        word-wrap: break-word;
        line-height: 12px;
        letter-spacing: 0.25px;
        opacity: 0.7;
    }
    .tdFirst{
        font-size: 23px;
        min-width: 40px;
        font-weight: 700;
        /* identical to box height, or 62% */

        text-align: center;

        /* VERDE TENNANT */

        color: #8BC93D;

        opacity: 0.7;
    }
    .tdAction{
        flex:1;
        min-width: 0px;
        width: 100%;
        max-width: none;
    }
    
    .tableMobile{
        display: flex;
        flex: 1;
        flex-direction: row;
        width: 100%;
    }
    .scrollTableMobile{
        display: flex;
        flex-direction: column;
        width: 85%;
    }
    .fixedTableMobile{
        display: flex;
        flex-direction: column;
        width: 15%;
    }
    .footer_container {
        width: 100%;
        display: flex;
        flex:1;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 0px;
    }
    .pagesButtons_container {
        display: flex;
        flex:0.7;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
    .footerButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 10px;
        gap: 5px;
    
        width: auto;
        height: auto;
        flex:1;
    
        background: #FFFFFF;
        border-radius: 6px;
        border: 0;
        color: #20ACD9;
        font-weight: bold;
    }
    .rowsButton_container {
        flex: 0.3;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 5px;
        margin: 0px 0px;
    }
    .rowsButtonLabel {
        font-size: 11px;
        color:#20ACD9 ;
        flex: 1;
    }
    .selectPages{
        font-size: 12px;
        font-weight: 700;
        color: #20ACD9;
        background-color: #FFFFFF;
    }
    
    .greenColor{
        color: #8BC93D;
    }
    .blueColor{
        color: #20ACD9;
    }
    .orangeColor{
        color: #FC8D41;
    }
    .iconContainerUsageBar {
        width: 215px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
@media (max-width:600px){
    .scrollTableMobile{
        width: 80%;
    }
    .fixedTableMobile{
        width: 20%;
    }

}
