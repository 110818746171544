.titleLabel,
.titleLabelBlue {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 39px;
	color: #323539;
}

.titleLabelBlue {
	color: #20acd9;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 2vh;
	margin: 0 2%;
}

.sites_container {
	display: flex;
	flex: 1;
	width: 100%;
	overflow-x: auto;
}

.sitesScroll {
	flex: 1px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 3vh;
}
.sites_container::-webkit-scrollbar {
	display: none;
}

.subHeader_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #323539;
}

.subHeader_labelColored {
	color: #20acd9;
}

.table_main_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
	background: #ffffff;
	margin-bottom: 2%;
}
.table_container {
	width: 95%;
	margin-bottom: 1rem;
	margin-top: 3rem;
}

.filter_mobile {
	display: flex;
	align-items: flex-start;
	margin-bottom: 2vh;
}

.filterBar_container {
	width: 95%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background: #ffffff;
}

.filterBar_header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
}

.filterLabel {
	width: 44px;
	height: 18px;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;

	/* txt FRA */

	color: #323539;

	opacity: 0.8;
}

.filterBar_body {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	gap: 2vh;
}

.select_container {
	width: 100%;
}

.resetFilters_btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 10px;
	gap: 8px;

	background: #f2f7f7;
	border-radius: 20px;
	border: 0;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	/* identical to box height */

	/* txt FRA */

	color: #323539;
}

.resetFiltersIcon {
	width: 10px;
	height: 10px;
}

.searchBtn_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 2vh 0;
	margin-right: auto;
}

.searchBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;

	width: 144.33px;
	height: 41.33px;
	border: 0;

	background: #09556f;
	border-radius: 10px;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;

	color: #ffffff;
}

@media (max-width: 900px) {
	.container {
		margin: 0px 20px;
	}
	.title_container {
		display: flex;
		flex: 1;
		width: 100%;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		margin-bottom: 10px;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 700;
		font-size: 23px;
		color: #323539;
	}
	.title_labelColored {
		color: #20acd9;
	}
	.filterBar_container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		background: transparent;
	}
	.filterBar_body_hide {
		overflow: hidden;
		background-color: white;
		gap: 20px;
		z-index: 1;
		display: flex;
		flex-direction: column;
		height: 0px;
		width: 100%;
		border-radius: 10px;
		align-items: center;
		transition: all 0.3s linear;
	}
	.filterBar_body {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 10px;
		padding: 10px 0px;
		background-color: white;
		z-index: 1;
		height: 300px;
		width: 100%;
		border-radius: 10px;
		align-items: center;
		transition: all 0.3s linear;
		animation: cambiaStile 0.5s ease 0.5s forwards;
	}
	.searchBtn_container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 2% 0px;
		margin-right: auto;
	}
	@keyframes cambiaStile {
		to {
			overflow: visible;
		}
	}
	.select_container {
		display: flex;
		flex: 1;
		width: 100%;
	}
	.resetFilters_btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 80%;
		margin: 0px 10px 20px 10px;
		padding: 15px 0px;
		gap: 8px;
		background: #f2f7f7;
		border-radius: 5px;
		border: 1px solid #20acd9;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		color: #323539;
	}
	.table_main_container {
		width: 100%;
		padding: 0px;
		margin-bottom: 0px;
		background-color: transparent;
	}
	.table_container {
		width: 100%;
		margin-bottom: 0px;
		margin-top: 0px;
		display: flex;
		flex-direction: column;
	}
}
