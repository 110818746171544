.container {
    background-color: #F2F6F6;
    display: flex;
    width: 100%;
    min-height: 100vh;
    /* justify-content: center; */
}

.navClosed {
    background-color: white;
}

.main_container {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
    overflow-x: auto;
}

.titleLabel {
    width: 100%;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 39px;
    color: #323539;
}
.sidebar{
    display: flex;
}

@media (max-width: 900px){
    .sidebar{
        display: none;
    }
}