.container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.green{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */


    /* VERDE TENNANT */

    color: #8BC93D;
}

.orange{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    /* identical to box height, or 100% */


    /* VERDE TENNANT */

    color: #FC8D41;
}