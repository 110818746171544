.conditions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em 1em 2em 1em;
    /* margin: 50px; */
    /*height: 80vh;*/
    /* width: 0vw; */
    width: 100%;
    height: 90vh;
    /*background-color: #F2F6F6;*/
}

.containerDocument {
    width: 60vw;
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    text-align: justify;
    margin: 1em 1em 2em 1em;
    padding: 2em 1em 2em 1em;

    border: #9b9b9b 1px solid;
    border-radius: 10px;
}

.paragraph {

}

.indentedParagraph {
    padding-left: 4em;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
}

.button {
    display: flex;
    padding: 15px 100px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: #09556f;
    color: #ffffff;
    border: 0;
    cursor: pointer;
    font-size: 1.5em;
}
.button:disabled {
    background: #9b9b9b;
    cursor: not-allowed;
}

.pageNavigation {
    display: flex;
    margin: 1em 1em 2em 1em;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 35px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #09556f;
    border-radius: 8px;
    transition: all 0.4s;
    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #20acd9;
    border-radius: 16px;
}


@media (max-width: 900px) {

}