.container {
    background-color: #F2F6F6;
    height: fit-content;
    /* width: 50vw; */
    /* display: flex; */
    /* flex: 0.3; */
    /* flex-direction: column; */
    height: 100vh;
    width: 25vw;
}

.container_logoTop {
    height: 33vh;
    display: flex;
    align-items: center;

}

.container_logoMiddle {
    height: 34vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_logoBottom {
    display: flex;
    height: 33vh;
    align-items: center;
    justify-content: flex-end;
    margin-right: -60%;
}

.logo_top {
    /* display: flex; */
    /* align-items: start; */
    /* justify-content: left; */
    margin-left: -30%;
    width: 35vw;

}

.logo_bottom {
    display: flex;
    justify-content: flex-end;
    width: 33vw;
}

.mask_group {
    width: 20vw;
}