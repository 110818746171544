.input_date,
.input {
    border-radius: 4px;
    background-color: #F2F6F6;
    padding: 0 10px;
    gap: 10px;
    height: 3em;
    border: none;
	width: 100%;
}

.input_error {
    border: red solid thin;
}

.form_group {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.form_group > *:only-child {
    grid-column: 1 / -1;
}

.onlyLabel {
    height: 100%;
	display: flex;
	align-items: center;
    margin-right: 5px;
	width: 70%;
}

.labelWithIcon {
    height: 100%;
	display: flex;
	align-items: center;
    margin-right: 5px;
	width: 70%;
}

.iconWithTooltip {
	position: relative;
	display: inline-block;
	margin: 6px;
}

.infoIcon {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
	border: 0.7px solid #323539;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: bold;
	font-size: small;
	line-height: 18px;
	color: #323539;
	opacity: 0.7;
}

.tooltipText {
	height: 30px;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: bold;
	font-size: small;
	color: #585d64;
	visibility: hidden;
	width: 20vw;
	background-color: #ffffff;
	text-align: center;
	border-radius: 5px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0.7;
	transition: opacity 0.3s;
	border: 0.1px solid #585d64;
}

.iconWithTooltip:hover .tooltipText {
	visibility: visible;
	opacity: 1;
}

@media (max-width:900px){
    .form_group{
        display: flex;
        width: 100%;
        flex:1;
        flex-direction: column;
        align-items: stretch;
    }
    .input {
        border-radius: 4px;
        background-color: #F2F6F6;
        padding: 10px;
        gap: 0px;
        height: 15px;
        width: auto;
        display: flex;
        border: none;
    }
    .input_date{
        border-radius: 4px;
        background-color: #F2F6F6;
        padding: 0px 10px;
        gap: 0px;
        height: 35px;
        width: auto;
        color: black;
        display: flex;
        border: none;
    }
}