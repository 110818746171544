.main_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 3.5vh;
	margin: 0 2%;
	margin-bottom: 2%;
}

.map_container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	background-color: white;
	flex-direction: column;
}

.map {
	width: 98%;
	height: 369px;
	margin: 10px;
}

.searchBtnContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.searchBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;

	width: 144.33px;
	height: 41.33px;
	border: 0;

	background: #09556f;
	border-radius: 10px;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;

	color: #ffffff;
}

.siteNumbers_main_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 3vh;
}

.siteNumbers_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 25px 50px;
	gap: 50px;
	width: 100%;
	background: #ffffff;
	border-radius: 10px;
}

.siteNumbers_numberLabel {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 47px;
	line-height: 54px;
	color: #20acd9;
}

.siteNumbers_titleLabel {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	color: #323539;
}
.siteNumbers_subtitle_label {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 20px;
	color: #323539;
}

.box_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #ffffff;
    border-radius: 25px;
}

.box_subContainer {
	width: 95%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: #ffffff;
	gap: 2.5vh;
	margin: 1rem 0;
}

.titleLabel {
	width: 100%;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 39px;
	color: #323539;
}

.inputContainer {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 2vw;
}

.inputContainer > * {
	display: flex;
	align-items: center;
	justify-content: center;
}

.label {
	font-family: Helvetica;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	opacity: 0.7;
	width: 150px;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 10px;
}

.filterbar_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px 20px 0px 20px;
	height: 89px;
	background: #ffffff;
	border-radius: 25px;
}

.box_grafici_1 {
	background: #ffffff;
	border-radius: 25px;
	/* height: 10vh; */
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	flex: 0.3;
	padding: 25px 0px;
}

.box_chart {
	width: 95%;
	display: flex;
	flex-direction: column;
}

.title_chart_container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 1vh;
}
.title_chart {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 39px;
	color: #323539;
}

.chartLegend {
	width: 10px;
	height: 10px;
	background: #fc8d41;
	border-radius: 10px;
}
.chartLegendLabel {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 16px;
	/* identical to box height */

	/* txt FRA */

	color: #323539;

	opacity: 0.7;
}

.chart {
	display: flex;
	justify-content: flex-start;
}

.tableHeader {
	width: 95%;
	display: flex;
	margin-top: 1rem;
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 700;
	font-size: 34px;
	line-height: 39px;
	/* identical to box height */

	/* txt FRA */

	color: #323539;
}

.table_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4vh;
	background: #ffffff;
	border-radius: 25px;
	margin-bottom: 5rem;
}

.table_subContainer {
	width: 95%;
	margin-bottom: 1rem;
}
@media (max-width: 900px) {
	.tableHeader {
		font-size: 18px;
		overflow: auto;
		line-height: 20px;
	}
	.table_subContainer {
		width: 90%;
		padding: 20px 0px 0px;
	}
	.main_container {
		gap: 20px;
		margin: 0 20px 20px;
		overflow: hidden;
		align-items: normal;
	}
	.map_container {
		display: flex;
		width: 100%;
		overflow: hidden;
		justify-content: center;
		align-items: center;
		background-color: white;
		flex-direction: column;
		border-radius: 25px;
	}

	.map {
		width: 96%;
		height: 250px;
		border-radius: 15px;
		margin: 8px 0px;
	}
	.filterbar_container {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: auto;
		width: 100%;
		flex: 1;
		padding: 20px 0px;
		background: white;
		border-radius: 25px;
	}
	.box_period {
		margin: 0px 10px;
	}

	.siteNumbers_main_container {
		width: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
		flex-wrap: wrap;
		justify-content: stretch;
	}
	.siteNumbers_container {
		padding: 30px 20px;
		gap: 10px;
		width: auto;
		flex: 0.5;
	}
	.siteNumbers_numberLabel {
		flex: 1;
		width: auto;
		font-size: 40px;
		line-height: 44px;
	}
	.siteNumbers_titleLabel {
		flex: 1;
		width: auto;
		font-size: 14px;
		word-wrap: break-word;
		line-height: 18px;
		color: #323539;
	}
	.siteNumbers_subtitle_label {
		width: auto;
		flex: 1;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 18px;
		color: #323539;
	}
	.siteNumbers_container_last {
		flex: 1;
	}
	.titleSiteMobile {
		font-size: 23px;
		font-weight: 700;
		font-family: 'Helvetica';
		line-height: 25px;
		margin-bottom: 10px;
	}
	.subtitleSiteMobile {
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		font-family: 'Helvetica';
	}
	.titleMobileContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.subtitleSiteMobileContainer {
		display: flex;
		flex-direction: row;
		flex: 1;
		align-items: center;
		gap: 10px;
	}
	.subtitleSiteMobileIcon {
		width: 15px;
		height: 15px;
	}

	.box_grafici_1 {
		background: #ffffff;
		border-radius: 25px;
		/* height: 10vh; */
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		flex: 1;
		padding: 0px 0px;
	}
	.box_chart {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
	}

	.title_chart_container {
		display: flex;
		width: auto;
		align-self: flex-start;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 0px;
		flex: 1;
		margin: 20px 20px 0px;
	}
	.title_chart {
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 20px;
		color: #4f4f4f;
	}
	.containerLegend {
		display: flex;
		flex-direction: row;
		gap: 10px;
		margin: 10px 0px;
	}
	.chartLegend {
		width: 10px;
		height: 10px;
		background: #fc8d41;
		border-radius: 10px;
	}
	.chartLegendLabel {
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 16px;
		/* identical to box height */
		/* txt FRA */
		color: #323539;
		opacity: 0.7;
	}

	.chart {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		font-size: 10px;
	}
}
