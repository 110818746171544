
.machine_main_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
    background: #FFFFFF;
    border-radius: 25px;
}
.machine_container{
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.machineDetailsHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.machineIcon{
    width: 77.57px;
    height: 89px;
}


.machineDetailsTitle{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    letter-spacing: 0.25px;

    /* LOGO */

    color: #148AAD;
}

.machineDetailsSubtitle{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    letter-spacing: 0.25px;

    /* txt FRA */

    color: #323539;
}

.machineDetails_legend{
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3vh;
}

.machineUsageAutonomus, .machineUsageTraining, .machineUsageManual{
    width: 8px;
    height: 8px;
    border-radius: 10px;
}

.machineUsageAutonomus{
    background: #09556F;
    border: 0.5px solid #09556F;
}
.machineUsageTraining{
    border: 0.5px solid #008000;
    background: #008000;
}
.machineUsageManual{
    background: #fdd663;
    border: 0.5px solid #fdd663   ;
}

.machineUsageLegendLabel{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1A1B1E;

    opacity: 0.7;
}

.active_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 17px;
    gap: 50px;

    width: 42px;
    height: 48px;

    /* grigio FRA */

    background: #F2F7F7;
    border-radius: 5px;
    border: 0;
}
.nonActive_btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 17px;
    gap: 50px;

    width: 49px;
    height: 48px;
    color: white;

    /* violet */

    background: #09556F;
    border-radius: 5px;
    border: 0;
}

.container_btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.machineUsage_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 25px;
    gap: 2vh;
}

@media (max-width:900px){
    .machine_main_container{
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: normal;
        justify-content: center;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 25px;
    }
    .machine_container{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 28px;
    }
    .machineDetailsHeader{
        width: 100%;
        display: flex;
        flex:1;
        flex-direction: column;
        gap:20px;
        align-items: normal;
        justify-content:center;
    }
    .container_mobile_header{
        display: flex;
        width: auto;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
    }
    .machineUsage_container{
        width: auto;
        align-items: flex-start;
        gap: 10px;
    }
    .machineDetails_legend{
        width: auto;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: flex-start;
        justify-content: space-between;
        gap: 0px;
    }
    .containerLegend{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap:5px
    }
    .machineUsageAutonomus,
    .machineUsageTraining,
    .machineUsageManual{
        margin: 2px;
    }
    .nonActive_btn{
        padding: 0px;
        gap:0px;
        width: 55px;
        height: 43px;
        font-size: 20px;
        font-weight: 300;
        font-family: 'Helvetica';
        background: #20acd9;
    }
    .active_btn {
        padding:0px;
        gap:0px;
        color: white;
        width: 55px;
        height: 43px;
        font-size: 20px;
        font-weight: 300;
        font-family: 'Helvetica';
        background: #20acd9;
    }
    .tableContainer{
        display: flex;
        flex:1;
    }

    
    

}