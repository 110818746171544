.container {
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: center;
 gap: 2vh;
 margin: 0 2%;
}

.searchBtn_container {
 display: flex;
 flex-direction: row;
 align-items: center;
 margin-right: auto;
}

.searchBtn {
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 gap: 8px;

 width: 144.33px;
 height: 41.33px;
 border: 0;

 background: #09556f;
 border-radius: 10px;

 font-family: 'Helvetica';
 font-style: normal;
 font-weight: 700;
 font-size: 16px;
 line-height: 18px;

 color: #ffffff;
}

.titleLabel {
 font-size: 34px;
 font-family: Helvetica;
 font-style: normal;
 font-weight: 700;
 line-height: normal;
}

.period_box {
 background-color: white;
 border: none;
 border-radius: 1em;
}

.chart_box {
 background-color: white;
 border: none;
 border-radius: 1em;
 max-width: 1000px;
 width: 75vw;
 padding: 15px 25px;
}

.country_box {
 background-color: white;
 border: none;
 border-radius: 1em;
 width: 75vw;
 max-width: 1000px;
 height: auto;
 padding: 15px 25px;
}

.map_stats_container {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.map {
 margin-top: 20px;
 width: 100%;
 height: 100%;
}

.stats_container {
 display: flex;
 flex-direction: column;
 padding-top: 20px;
 max-width: 20vw;
 width: 100%;
}

.stats {
 width: 100%;
 height: 100%;
 margin-bottom: 10px;
}

.stats_title_container {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin-bottom: 10px;
}

.stats_title {
 font-family: 'Helvetica', sans-serif;
 font-weight: 700;
 color: gray;
 font-size: 16px;
 text-transform: uppercase;
}

.stats_item {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 border-top: 1px solid #ccc;
 padding: 8px 0;
 font-size: 14px;
 font-family: 'Helvetica', sans-serif;
}

.country_name {
 flex-grow: 2;
}

.table_value {
 display: flex;
}

.percentage_value {
 width: 3em;
 text-align: end;
}

.percentage_up {
 color: green;
}

.percentage_down {
 color: red;
}

.users_number_box {
 background-color: #eaf4f7;
 border: none;
 border-radius: 1em;
 display: flex;
 align-items: flex-start;
 justify-content: left;
 flex: 0.3;
 width: 110px;
 padding: 15px 25px;
 margin-top: 15px;
}

.box_title {
 font-family: 'Helvetica', sans-serif;
 font-weight: 700;
 font-size: 18px;
}

.users_number_title {
 width: 44px;
 height: 18px;
 font-family: 'Helvetica', sans-serif;
 font-weight: 700;
 font-size: 16px;
}

.users_number_container {
 padding-top: 10px;
}

.users_number {
 width: 44px;
 height: 18px;
 font-family: 'Helvetica', sans-serif;
 color: #20acd9;
 font-weight: 700;
 font-size: 20px;
}

@media (max-width: 900px) {
 .container {
  padding: 10px;
 }

 .period_box {
  width: 100%;
  min-width: unset;
 }

 .map_stats_container {
  flex-direction: column;
  align-items: center;
 }

 .stats_container {
  max-width: 100%;
  width: 100%;
 }

 .chart_box {
  width: 85vw;
  max-width: 880px;
  padding: 9px 15px;
 }

 .country_box {
  width: 85vw;
  max-width: 1000px;
  height: auto;
  padding: 9px 15px;
 }
}
